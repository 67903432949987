import React, { useContext, useEffect, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Context as AuthContext } from '../context/AuthContext'
import api from '../api/api'
import { Redirect } from 'react-router-dom'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

const LoginSchema = Yup.object().shape({
  iso2: Yup.string()
    .required('Required'),
  phone: Yup.string()
    .required('Required')
    .matches(/^[\d\s]+X?$/, 'Invalid phone number!'),
  password: Yup.string()
    .required('Required')
})

export default function LoginFormPage () {
  // State.
  const accessToken = localStorage.getItem('accessToken')
  const { state: { loading, formError }, login, setError } = useContext(AuthContext)
  const iso2 = process.env.NODE_ENV === 'development' ? 'CA' : 'AE'
  const phone = process.env.NODE_ENV === 'development' ? '222222222' : ''
  const password = process.env.NODE_ENV === 'development' ? '152854' : ''
  const [countries, setCountries] = useState([])

  // Effects.
  useEffect(() => {
    // API call.
    const fetchCountries = async () => {
      console.log('Fetching countries')
      const response = await api.post('/security/get-countries')
      if (response.data.success) {
        setCountries(response.data.countries)
      } else {
        // TODO: Implement.
      }
    }
    fetchCountries()
  }, [])

  // Styles.
  const classes = useStyles()

  // If we have successfully obtained the token, then redirect to main.
  if (accessToken) {
    return <Redirect to='/' />
  }

  return (
    countries && countries.length > 0 &&
    (
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
          Sign in
          </Typography>
          <Formik
            className={classes.form}
            initialValues={{ iso2: iso2, phone: phone, password: password }}
            onSubmit={login}
            validationSchema={LoginSchema}
            validateOnBlur
            validateOnChange
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <InputLabel id='country-label' shrink>Country</InputLabel>
                <Field
                  component={Select}
                  name='iso2'
                  value={values.iso2}
                  disabled={loading}
                  error={errors.iso2 && touched.iso2}
                  fullWidth
                  id='iso2'
                  labelId='country-label'
                  onBlur={handleBlur}
                  inputProps={{
                    name: 'iso2',
                    id: 'iso2-native-helper'
                  }}
                  onChange={(value) => {
                    formError && setError(null)
                    handleChange(value)
                  }}
                  size='small'
                  margin='dense'
                  children={countries.map(i => <MenuItem key={i.Iso2} value={i.Iso2}> {i.Name} </MenuItem>)}
                />
                <Field
                  autoComplete='tel-national'
                  autoFocus
                  component={TextField}
                  value={values.phone}
                  disabled={loading}
                  error={errors.phone && touched.phone}
                  fullWidth
                  helperText={errors.phone && touched.phone ? errors.phone : ''}
                  id='phone'
                  label='Phone'
                  margin='normal'
                  name='phone'
                  onBlur={handleBlur}
                  onChange={(value) => {
                    formError && setError(null)
                    handleChange(value)
                  }}
                  size='small'
                  type='tel'
                  variant='outlined'
                />
                <Field
                  autoComplete='current-password'
                  component={TextField}
                  value={values.password}
                  disabled={loading}
                  error={errors.password && touched.password}
                  fullWidth
                  helperText={errors.password && touched.password ? errors.password : ''}
                  id='password'
                  label='Password'
                  margin='normal'
                  name='password'
                  onBlur={handleBlur}
                  onChange={(value) => {
                    formError && setError(null)
                    handleChange(value)
                  }}
                  size='small'
                  type='password'
                  variant='outlined'
                />
                {formError &&
                  <Typography
                    align='center'
                    className={classes.errorMessage}
                    color='error'
                    variant='body1'
                  >
                Incorrect login details!
                  </Typography>}
                <div className={classes.wrapper}>
                  <Button
                    className={classes.submit}
                    color='primary'
                    disabled={loading}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                  Sign In
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    )
  )
}

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1)
  },
  buttonProgress: {
    color: 'primary',
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
    position: 'absolute',
    top: '50%'
  },
  errorMessage: {
    marginTop: theme.spacing(2)
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%' // Fix IE 11 issue.
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(8)
  },
  wrapper: {
    margin: theme.spacing(3, 0, 2),
    position: 'relative'
  }
}))
