import api from '../api/api'
import sha256 from 'crypto-js/sha256'

export default (dispatch) => async ({ iso2, phone, password }) => {
  console.log('Calling login')
  // Modify state, so the button knows that we're requesting data.
  dispatch({ type: 'set_loading', payload: { key: 'loading', value: true } })

  try {
    // Call API.
    const response = await api.post('/security/login', {
      applicationId: 'patient_verification_service',
      iso2,
      phone,
      pin: sha256(password).toString()
    })

    // Deconstruct response.data.
    const { success, errCode, accessToken, refreshToken, user, canUploadResults } = response.data

    // Store tokens.
    if (success) {
      // Store tokens.
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('canUploadResults', canUploadResults)

      // Update Axios header.
      api.defaults.headers.common.Authorization = `Bearer ${accessToken}`

      // Everything done, so stop loading.
      dispatch({ type: 'set_loading', payload: { key: 'loading', value: false } })
      dispatch({ type: 'set_user', payload: { user, canUploadResults } })

      // Save tokens in state. This will redirect to main page.
      dispatch({ type: 'set_tokens', payload: { accessToken, refreshToken } })
    } else if (!success && errCode) {
      dispatch({ type: 'set_loading', payload: { key: 'loading', value: false } })
      dispatch({ type: 'set_error', payload: { key: 'formError', value: errCode } })
    }
  } catch (e) {
    // TODO: Implement.
  }
}
