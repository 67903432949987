import createDataContext from './createDataContext'
import login from './../actions/login'

const initialState = {
  // Loaders.
  loading: false,

  // Tokens.
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),
  user: localStorage.getItem('user'),
  canUploadResults: localStorage.getItem('canUploadResults'),

  // Errors.
  formError: null
}

const setError = dispatch => (error) => {
  dispatch({ type: 'set_error', payload: { key: 'formError', value: error } })
}

const authReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'set_loading':
      return { ...state, [payload.key]: payload.value }

    case 'set_error':
      return { ...state, [payload.key]: payload.value }

    case 'set_tokens':
      return { ...state, accessToken: payload.accessToken, refreshToken: payload.refreshToken }

    case 'set_user':
      return { ...state, user: JSON.stringify(payload.user), canUploadResults: payload.canUploadResults }

    default:
      return state
  }
}

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    login,
    setError
  },
  initialState
)
