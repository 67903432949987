import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'

// Context.
import { Provider as AuthProvider } from './context/AuthContext'

// Pages.
import LoginFormPage from './pages/LoginFormPage'
import AuthLoadingPage from './pages/AuthLoadingPage'

// History.
import appHistory from './navigation/history'

function App () {
  return (
    <AuthProvider>
      <Router history={appHistory}>
        <Switch>
          <Route path='/login' component={LoginFormPage} />
          <Route path='/' component={AuthLoadingPage} />
        </Switch>
      </Router>
    </AuthProvider>
  )
}

export default App
