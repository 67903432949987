import axios from 'axios'
import Urls from '../consts/Urls'
import ErrorCodes from '../consts/ErrorCodes'
import decodeJwt from 'jwt-decode'
import appHistory from '../navigation/history'

const api = axios.create({
  baseURL: Urls.baseHttpUrl
})

// Intercept response and check for errors.
api.interceptors.response.use(async response => {
  const { data: { success, errCode } } = response
  if (!success && (errCode === ErrorCodes.invalid_token)) {
    console.log('Recived invalid_token error!')

    // Failed to refresh access token.
    if (response.config.url === '/security/refresh-access-token') {
      console.log('Failed to refresh access token, going Auth.')
      return Promise.reject(new Error('Failed to refresh access token.'))
    }

    const refreshToken = await localStorage.getItem('refreshToken')
    const applicationId = 'patient_verification_service'

    if (refreshToken && applicationId) {
      // Check if refresh token is still valid.
      // Decode refreshToken.
      const decodedRefreshToken = decodeJwt(refreshToken)

      // If refresh token is not expired then refresh main token.
      const secondsSinceEpoch = Math.floor(Date.now() / 1000)
      if (decodedRefreshToken.exp < secondsSinceEpoch) {
        // Remove stored tokens and data.
        cleanUp()

        return Promise.reject(new Error('Refresh token is expired'))
      }

      // Save config in order to run the failed request after successfully refreshing token.
      const failedRequest = response.config

      // Update Axios header to use refresh token instead.
      api.defaults.headers.common.Authorization = `Bearer ${refreshToken}`

      console.log('Fetching new token...')
      return api.post('/security/refresh-access-token', {
        applicationId
      })
        .then(refreshTokenResponse => {
          const { success, accessToken, errCode } = refreshTokenResponse.data
          if (success && accessToken && !errCode) {
            console.log(`Received new token: ${accessToken}`)

            // Update Axios header to use accessToken instead of refresh token.
            api.defaults.headers.common.Authorization = `Bearer ${accessToken}`

            // Store new token.
            localStorage.setItem('accessToken', accessToken)

            // Change failed request token to the new one.
            failedRequest.headers.Authorization = `Bearer ${accessToken}`

            // Run queued request.
            return api(failedRequest)
          } else {
            return Promise.reject(new Error('Failed to refresh access token.'))
          }
        })
        .catch((error) => {
          // Remove stored tokens and data.
          cleanUp()

          return Promise.reject(error)
        })
    } else {
      // Remove stored tokens and data.
      cleanUp()

      return Promise.reject(new Error('No refresh token or application id available'))
    }
  } else if (!success && (errCode === ErrorCodes.auth_failed || errCode === ErrorCodes.access_denied)) {
    console.log(`Received ${errCode}, going Auth`)

    // Remove stored tokens and data.
    cleanUp()

    return Promise.reject(new Error(errCode))
  } else {
    // All went fine.
    return Promise.resolve(response)
  }
}, error => {
  return Promise.reject(error)
})

const cleanUp = () => {
  // Remove the headers.
  api.defaults.headers.common.Authorization = null

  // Clean storage.
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('user')
  localStorage.removeItem('canUploadResults')

  // Go to login page.
  appHistory.replace('/login')
}

export default api
