import React from 'react'
import { Redirect } from 'react-router-dom'
import MainPage from './MainPage'
import api from './../api/api'

export default function AuthLoadingPage () {
  const accessToken = localStorage.getItem('accessToken')

  console.log(`Using access token ${accessToken}`)
  if (!accessToken) {
    return <Redirect to='/login' />
  } else {
    // Make sure Axios uses current token!
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  }

  return <MainPage />
}
